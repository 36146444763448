<script setup>
import Hamburger from '~/assets/icons/Hamburger.svg';
import LogoAzimutRgbReverse from '~/assets/icons/Logo_Azimut_RGB_Reverse.svg';
import LogoAzimutRgbReverseHorizontal from '~/assets/icons/Logo_Azimut_RGB_Reverse_Horizontal.svg';

// const navOpened = ref(false);
const {
    navOpened,
} = useClientState();

</script>
<template>
    <header class="fixed left-0 top-0 z-20 flex h-22 w-full justify-between border-b border-white bg-nuit pb-6 pt-10 lg:left-auto lg:right-0 lg:block lg:h-full lg:w-24 lg:border-b-0 lg:border-l lg:py-0">
        <MainLink class="hidden lg:absolute lg:bottom-7 lg:left-[50%] lg:block lg:-translate-x-1/2" to="/">
            <LogoAzimutRgbReverse />
        </MainLink>
        <MainLink class="lg:hidden" to="/">
            <LogoAzimutRgbReverseHorizontal class="-mt-5 ml-7 h-auto w-36 xs:w-45" />
        </MainLink>
        <button class="relative z-10 -translate-x-1/2 cursor-pointer text-white transition hover:text-carmin lg:left-[50%] lg:top-11" v-on:click="navOpened = !navOpened">
            <Hamburger class="fill-none stroke-current" />
        </button>
        <PartialsNavMobile />
    </header>
</template>
